import React from 'react';
import c from './categories.module.scss';
import CategoryGroup from './category/category-group/CategoryGroup';

const CATEGORY_TYPES = ['topic', 'group'];

const Categories = ({ data }) => {
  return (
    <section aria-label="Categories" className={c.categories}>
      {CATEGORY_TYPES.map((type) => (
        <CategoryGroup
          key={type}
          type={type}
          categories={data.filter((category) => category.type === type)}
        />
      ))}
    </section>
  );
};

export default Categories;
