import React from 'react';
import c from './banner.module.scss';
import homeLoading from '../../../../assets/lotties/home-latest.json';
import { useMedia } from '@monash/portal-react';
import { RenderAnimation } from '@monash/portal-frontend-common';

const Banner = () => {
  const size = useMedia();

  return (
    <div className={size === 'S' ? [c.banner, c.mini].join(' ') : c.banner}>
      <div className={c.header}>
        <h1>Looking for something?</h1>
        <p>
          You can search and browse to find web pages, forms, platforms, and
          more!
        </p>
      </div>

      <RenderAnimation lottieFile={homeLoading} aspectRatio="xMinYMax meet" />
    </div>
  );
};

export default Banner;
