import React from 'react';
import { Icon } from '@monash/portal-react';
import c from './search-result-card.module.scss';
import { getIcon } from '@monash/portal-frontend-common';

const loadingBarsCount = 4;

const SearchResultsCard = ({ entity, loading, index }) => {
  if (!loading && entity?.entityType !== 'link') return null;
  return (
    <div className={c.entity}>
      {(loading || !entity) && (
        <div className={c.fakeLink} aria-live="polite" data-testid="loading">
          <div className={c.icon} />
          <div className={c.name} />
          <div className={c.description}>
            {Array(loadingBarsCount)
              .fill('')
              .map((item, i) => (
                <div className={c.bar} key={i} />
              ))}
          </div>
        </div>
      )}
      {!loading && (
        <a
          id={`searchCard-${index}`}
          className={c.link}
          href={entity.url}
          target="_blank"
          rel="noreferrer"
          aria-label={`${entity.name}. ${entity.description}. Opens in a new window.`}
        >
          <div className={c.linkHeader}>
            {getIcon(entity.app)}
            <div className={c.externalIcon}>
              <Icon.External size={24} color="var(--card-text-color)" />
            </div>
          </div>
          <h3>{entity.name}</h3>
          <p>{entity.description}</p>
        </a>
      )}
    </div>
  );
};

export default SearchResultsCard;
